import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

const CM = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #81c4de, #73b9d4, #5897b0, #438aa6, #317793 )",
        icons: "#81c4de",
        navClass: "cm",
      }}
      seo={{
        title: "Crystal Mountain",
        headerTitle: "cm",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/cm_top_back.jpg"),
      }}
      //   langSwitch={{
      //     langKey: "en",
      //     langSlug: "/en/projects/kw51/",
      //   }}
    >
      <PortfolioHeader name="cm" height="276" />
      <section className="container-fluid cm_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Crystal Mountain</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Materiały marketingowe</li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="inner">
              <p>
                Deweloper należy do grupy Osada Śnieżka, w skład której wchodzą
                powstałe kompleksy: Osada Śnieżka w Łomnicy, Osada Śnieżka
                Medical Spa w Łomnicy, Blue Mountain Resort w Szklarskiej
                Porębie, Platinum Mountain Resort w Szklarskiej Porębie, Green
                Mountain Resort w Karpaczu oraz Crystal Mountain Resort w Wiśle.
                <br></br>
                <br></br>
                Są to wysokiej klasy kompleksy hotelowe działające w systemie
                condo, gwarantujące stały zysk na wysokim poziomie stopy zwrotu.
                Interesujący nas obiekt znajduje się w sercu Karkonoszy i
                zawiera pokoje hotelowe oraz apartamenty, których promocją
                zajmowała się nasza agencja.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid cm_section_3">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/cm_fullpage.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid cm_section_4">
        <div className="row">
          <div className="col-md-5">
            <img
              src={require("../../assets/img/portfolio/cm_img_1.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="inner inner_0">
              <h3>Strona internetowa</h3>
              <p>
                Strona internetowa https://www.crystal-mountain.pl została
                zrealizowana dla dewelopera działającego na rynku inwestycji
                aparthotelowych. Najważniejszym zadaniem, jakiemu mieliśmy
                sprostać przy projekcie strony było stworzenie unikalnych
                widoków graficznych, które pozwolą pokazać prestiż inwestycji –
                hotelu o wysokim standardzie, położonegow jednym z
                najpopularniejszych kurortów wypoczynkowych w Polsce, w
                okolicach góry Śnieżki i sąsiedztwie Kurzych Skał, stanowiących
                atrakcję dla miłośników wspinaczek górskich.
              </p>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="inner inner_1">
              <p>
                Chcieliśmy także skupić się na przedstawieniu bardzo bogatej
                oferty usługowo-rekreacyjnej, znajdującej się w hotelu. Projekt
                layoutu graficznego zdeterminowany był także maksymalizacją
                użyteczności strony oraz, co niezwykle ważne, realizacją celów
                biznesowych Klienta. Najważniejszą funkcją strony było
                pozyskiwanie leadów od potencjalnych klientów.Wszystkie
                formularze na stronie zostały połączone z zewnętrznym systemem
                do zautomatyzowanej obsługi zapytań.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={require("../../assets/img/portfolio/cm_img_2.png")}
              alt=""
              className="img-fluid img_1"
            />
          </div>
        </div>
      </section>
      <section className="container-fluid cm_section_5">
        <div className="row">
          <div className="col-md-5">
            <img
              src={require("../../assets/img/portfolio/cm_img_3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="inner">
              <h3>Materiały wspierające sprzedaz</h3>
              <p>
                Sprzedaż rozpoczyna się dopiero w momencie przekazania leadów
                naszemu Klientowi. Zadaniem agencji jest dostarczenie
                wartościowych kontaktów do potencjalnych inwestorów – to staje
                się podstawą dalszych działań po stronie dewelopera. Aby wspomóc
                Klienta w tym zadaniu przygotowaliśmy projekt prezentacji
                wspierającej sprzedaż – prezentacja w formie elektronicznej oraz
                drukowanej towarzyszyła Klientowi na spotkaniach biznesowych.
                Przygotowaliśmy obie prezentacje w trzech wersjach językowych –
                po polsku, niemiecku i angielsku.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid cm_section_6">
        <img
          src={require("../../assets/img/portfolio/cm_img_4.png")}
          alt=""
          className="img-fluid"
        />
      </section>
      <section className="container-fluid cm_section_7">
        <img
          src={require("../../assets/img/portfolio/cm_img_5.png")}
          alt=""
          className="img-fluid"
        />
      </section>

      <BanerMDD />
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default CM;
