import "./baner-mdd.scss";

import React from "react";
import { Link } from "gatsby";

const BanerMDD = ({ type }) => {
  return (
    <section
      className={`baner-mdd${type === "post" ? " baner-mdd__post" : ""}`}
    >
      <div className="container-fluid">
        <div className="baner-mdd__wrapper">
          <div>
            <h2>
              Marketing <br /> dla Deweloperów
            </h2>
            <p>Zobacz naszą ofertę skierowaną dla deweloperów</p>
          </div>
          <Link to="/marketing-dla-deweloperow/" className="btn btn-default">
            Sprawdź
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BanerMDD;
